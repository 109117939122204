export function getArtwork(asset) {
    let assetEl = document.getElementById(asset);
    if (assetEl && assetEl.hasLoaded) {
        if (typeof assetEl.data === 'string') {
            assetEl.data = JSON.parse(assetEl.data);
        }
        if (Array.isArray(assetEl.data)) {
            // in case of a list, return first item
            return assetEl.data[0];
        } else {
            return assetEl.data;
        }
    } else {
        return {};
    }
}

export const get3DElementSize = (function () {
    let boundsBox = new THREE.Box3();

    return function (el) {
        let boundsVec = new THREE.Vector3();
        boundsBox.setFromObject(el.object3D).getSize(boundsVec);
        return boundsVec;
    };
})();
