import { getArtwork } from './common';

AFRAME.registerComponent('art', {
    schema: {
        asset: { type: 'string', default: '' },
        frame: { type: 'string', default: '' },
        width: { type: 'number', default: 1 },
        light: { type: 'boolean', default: false },
    },
    frames: {
        default: {
            width: 1.0,
            height: 0.1,
            model: 'model/frame/frame_4.glb',
        },
        fancy: {
            width: 1.338,
            height: 0.1,
            model: 'model/frame/frame_1.glb',
        },
        fancy2: {
            width: 1.218,
            height: 0.076,
            model: 'model/frame/frame_3.glb',
        },
        none: {
            none: true,
            thickness: 0.03,
            width: 1,
            height: 0,
        },
    },
    init: function () {
        //console.log(this.data);
        this.assetEl = document.getElementById(this.data.asset);
        if (this.assetEl) {
            if (this.assetEl.hasLoaded) {
                this.draw();
            } else {
                this.assetEl.addEventListener('loaded', () => {
                    this.draw();
                });
            }
        } else {
            console.log(`asset not found yet; ${this.data.asset}`);
            setTimeout(this.init.bind(this), 100);
        }
    },
    draw: function () {
        console.log(`art loaded; ${this.data.asset}`);
        const { object3D, sceneEl } = this.el;

        const frame = this.frames[this.data.frame || 'default'];
        const addLight = this.data.light === undefined ? false : !!this.data.light;

        this.assetEl = document.getElementById(this.data.asset);

        let artData = getArtwork(this.data.asset);
        let imgSrc = artData.image ? atob(artData.image) : artData.src;

        this.id = 'art_' + Math.floor(1000 * Math.random());

        if (!frame.none) {
            this.bottomFrameEl = document.createElement('a-entity');
            this.bottomFrameEl.setAttribute('gltf-model', frame.model);
            this.topFrameEl = document.createElement('a-entity');
            this.topFrameEl.setAttribute('gltf-model', frame.model);
            this.topFrameEl.setAttribute('rotation', '0 0 180');
            this.leftFrameEl = document.createElement('a-entity');
            this.leftFrameEl.setAttribute('gltf-model', frame.model);
            this.leftFrameEl.setAttribute('rotation', '0 0 -90');
            this.rightFrameEl = document.createElement('a-entity');
            this.rightFrameEl.setAttribute('rotation', '0 0 90');
            this.rightFrameEl.setAttribute('gltf-model', frame.model);

            this.el.appendChild(this.bottomFrameEl);
            this.el.appendChild(this.topFrameEl);
            this.el.appendChild(this.leftFrameEl);
            this.el.appendChild(this.rightFrameEl);
        } else {
            this.fullFrameEl = document.createElement('a-box');
            this.fullFrameEl.setAttribute('width', 1);
            this.fullFrameEl.setAttribute('height', 1);
            this.fullFrameEl.setAttribute('depth', frame.thickness);

            this.el.appendChild(this.fullFrameEl);
        }
        this.imageEl = document.createElement('a-image');
        this.imageEl.setAttribute('src', imgSrc);
        this.imageEl.setAttribute('class', 'raycastable');
        this.imageEl.setAttribute('id', this.id + '_img');

        if (addLight) {
            this.lightEl = document.createElement('a-light');
            this.lightEl.setAttribute('position', `${this.data.width / 2} 2 2`);
            this.lightEl.setAttribute('light', 'type: spot; angle: 45; intensity: 0.2; penumbra: 0.5;');
            this.lightEl.setAttribute('target', `#${this.id}_img`);
            this.el.appendChild(this.lightEl);
        }

        this.el.appendChild(this.imageEl);

        this.hoverEl = document.createElement('a-plane');
        this.hoverEl.setAttribute('material', 'color', '#ff0');
        this.hoverEl.setAttribute('material', 'emissive', '#ff0');
        this.hoverEl.setAttribute('visible', 'false');
        this.el.appendChild(this.hoverEl);

        let scale = (ratio) => {
            let imw = this.data.width - 2 * frame.height;
            let imh = imw / ratio;
            let frw = this.data.width;
            let frh = imh + 2 * frame.height;

            let targetScaleX = frw / frame.width;
            let targetScaleY = frh / frame.width;

            if (frame.none) {
                this.fullFrameEl.setAttribute('scale', `${targetScaleX} ${targetScaleY} 1`);
                this.fullFrameEl.setAttribute('position', `${frw / 2} ${frh / 2} 0`);
            } else {
                this.bottomFrameEl.setAttribute('scale', `${targetScaleX} 1 1`);
                this.bottomFrameEl.setAttribute('position', `0 0 0`);

                this.topFrameEl.setAttribute('scale', `${targetScaleX} 1 1`);
                this.topFrameEl.setAttribute('position', `${frw} ${frh} 0`);

                this.leftFrameEl.setAttribute('scale', `${targetScaleY} 1 1`);
                this.leftFrameEl.setAttribute('position', `0 ${frh} 0`);

                this.rightFrameEl.setAttribute('scale', `${targetScaleY} 1 1`);
                this.rightFrameEl.setAttribute('position', `${frw} 0 0`);
            }
            this.imageEl.setAttribute('width', imw);
            this.imageEl.setAttribute('height', imh);

            let pos = [imw / 2 + frame.height, imh / 2 + frame.height, frame.thickness || 0.01];

            this.imageEl.setAttribute('position', pos.join(' '));

            pos[2] -= 0.01;
            this.hoverEl.setAttribute('width', frw + 0.1);
            this.hoverEl.setAttribute('height', frh + 0.1);
            this.hoverEl.setAttribute('position', pos.join(' '));
        };

        let im = new Image();
        im.onload = function () {
            scale(this.width / this.height);
        };
        im.src = imgSrc;

        this.el.addEventListener('mouseenter', this.pointerover.bind(this));
        this.el.addEventListener('mouseleave', this.pointerout.bind(this));
    },
    pointerover: function () {
        this.hoverEl.setAttribute('visible', 'true');
    },
    pointerout: function () {
        this.hoverEl.setAttribute('visible', 'false');
    },
    scale: function (ratio) {
        this.ratio = ratio;
    },
});
