import nipplejs from 'nipplejs';

/* joystick */

AFRAME.registerComponent('joystick', {
    schema: {
        speed: { type: 'number', default: 1 },
        constrainToNavMesh: { type: 'boolean', default: false },
    },
    init: function () {
        this.nippleContainer = document.createElement('div');
        this.nippleContainer.setAttribute(
            'style',
            'position: fixed; z-index: 2; right: 80px; bottom: 80px; user-select: none; -webkit-user-select: none;'
        );
        document.body.appendChild(this.nippleContainer);

        this.scaledSpeed = 0.06 * this.data.speed;
        this.movementSpeed = 0;
        this.movementDirection = 0;

        this.nipple = nipplejs.create({
            mode: 'static',
            zone: this.nippleContainer,
            position: { top: '0px', left: '0px' },
        });

        this.nipple.on('move', (evt, data) => {
            this.movementSpeed = data.force * this.scaledSpeed;
            this.movementDirection = data.angle.radian;
        });
        this.nipple.on('end', (evt, data) => {
            this.movementSpeed = 0;
            this.movementDirection = 0;
        });
    },
    pause: function () {
        this.nippleContainer.style.display = 'none';
    },
    play: function () {
        this.nippleContainer.style.display = 'block';
    },
    remove: function () {
        this.nipple.destroy();
        document.body.removeChild(this.nippleContainer);
    },
    update: function () {
        if (this.data.constrainToNavMesh) {
            this.el.sceneEl.systems.nav.addAgent(this);
        }
    },
    updateNavLocation: function () {
        this.navGroup = null;
        this.navNode = null;
    },
    tick: (function () {
        const start = new THREE.Vector3();
        const end = new THREE.Vector3();
        const clampedEnd = new THREE.Vector3();

        return function (time, timeDelta) {
            if (!timeDelta) return;

            if (this.movementSpeed !== 0) {
                let rig = this.el;
                let cam = document.querySelector('[camera]');
                let direction = this.movementDirection + cam.object3D.rotation.y + rig.object3D.rotation.y;

                let x_vec = Math.cos(direction);
                let z_vec = Math.sin(direction);

                start.copy(rig.object3D.position);
                end.set(this.movementSpeed * x_vec, rig.object3D.position.y, -this.movementSpeed * z_vec).add(start);

                if (this.data.constrainToNavMesh) {
                    const nav = this.el.sceneEl.systems.nav;
                    this.navGroup = this.navGroup === null ? nav.getGroup(start) : this.navGroup;
                    this.navNode = this.navNode || nav.getNode(start, this.navGroup);
                    this.navNode = nav.clampStep(start, end, this.navGroup, this.navNode, clampedEnd);

                    rig.object3D.position.copy(clampedEnd);
                } else {
                    rig.object3D.position.copy(end);
                }
            }
        };
    })(),
});
