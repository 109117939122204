import 'aframe-gif-shader';
import './art';
import './info';
import './joystick';

function getDeviceOrientationPermission(callback) {
    if (
        typeof DeviceOrientationEvent === 'undefined' ||
        !DeviceOrientationEvent.requestPermission
    ) {
        return;
    }
    DeviceOrientationEvent.requestPermission()
        .catch((err) => console.error(err))
        .then((permissionState) => {
            if (permissionState === 'granted') {
                // override a-frame device-orientation-permission-ui component stuff so things depending on it work
                let sceneEl = document.querySelector('a-scene').sceneEl;
                sceneEl.components[
                    'device-orientation-permission-ui'
                ].permissionGranted = true;
                sceneEl.emit('deviceorientationpermissiongranted');
                if (typeof callback === 'function') {
                    callback();
                }
            }
        });
}

function checkForARQuickLookSupport() {
    // check for AR Quick Look support
    const a = document.createElement('a');
    return a.relList.supports('ar') ? true : false;
}

// stuff to do after onload
$(function () {
    const $body = $('body');

    // add emissive color to loisteputki objects
    let model = document.querySelector('#model');
    let loisteputkiEmissiveColor = new THREE.Color(255, 255, 255);
    model.addEventListener('model-loaded', () => {
        model.object3D.traverse((o) => {
            if (o.name === 'loisteputki') {
                o.material.emissive = loisteputkiEmissiveColor;
            }
            if (o.material && o.material.metalness > 0) {
                o.material.metalness = 0;
            }
        });
    });
    // remove metalness from elevator materials
    let elevator = document.querySelector('#loading');
    elevator.addEventListener('model-loaded', () => {
        elevator.object3D.traverse((o) => {
            if (o.material && o.material.metalness > 0) {
                o.material.metalness = 0;
            }
        });
    });

    // bind click listener to popup closing button
    $('.popup .close').click((evt) => {
        $('.popup').addClass('hidden');
        document.querySelector('a-scene').play();
    });

    let scene = document.querySelector('a-scene');
    scene.addEventListener('enter-vr', () => {
        //document.querySelector('a-entity[camera]').setAttribute('look-controls', { pointerLockEnabled: true });
    });
    scene.addEventListener('exit-vr', () => {
        // hide vr mode info on vr mode exit
        document.querySelector('#vrinfo').setAttribute('visible', false);
        //document.querySelector('a-entity[camera]').setAttribute('look-controls', { pointerLockEnabled: false });
        document.querySelector('a-entity[camera]').setAttribute('position', '0 1.7 0');
    });

    // set intro popup depending on device type
    if (AFRAME.utils.device.isMobile() || AFRAME.utils.device.isTablet()) {
        $body.addClass('is-mobile');
        $('#intro .close').hide();
        $('#intro button').click((evt) => {
            $('#intro').hide();
            getDeviceOrientationPermission(() => {
                //$('#intro').hide();
                console.log('this not working on android?');
            });
        });
    } else if (AFRAME.utils.device.checkHeadsetConnected()) {
        $body.addClass('is-vr');
        $('#intro button').click((evt) => {
            $('#intro').hide();
        });
    } else {
        $body.addClass('is-desktop');
        // disable joystick on desktop
        document.querySelector('#rig').removeAttribute('joystick');
        $('#intro button').click((evt) => {
            $('#intro').hide();
        });
    }

    if (checkForARQuickLookSupport()) {
        $body.addClass('ar-ql');
    }

    $('#intro').removeClass('hidden');
});
