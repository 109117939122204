import { getArtwork, get3DElementSize } from './common';

/* info popup */
AFRAME.registerComponent('info', {
    schema: {
        asset: { type: 'string', default: '' },
        image: { type: 'string', default: '' },
        src: { type: 'string', default: '' },
        arsrc: { type: 'string', default: '' },
        name: { type: 'string', default: '' },
        author: { type: 'string', default: '' },
        year: { type: 'string', default: '' },
        technique: { type: 'string', default: '' },
        size: { type: 'string', default: '' },
        description: { type: 'string', default: '' },
        povPosAdjust: { type: 'vec3' },
        povPitchAdjust: { type: 'number' },
        povRotAdjust: { type: 'number' },
        povDistance: { type: 'number', default: 2 },
        vrInfoPosAdjust: { type: 'vec3' },
        vrInfoRotAdjust: { type: 'number' },
    },
    init: function () {
        this.el.addEventListener('mouseenter', this.pointerover.bind(this));
        this.el.addEventListener('mouseleave', this.pointerout.bind(this));
        this.el.addEventListener('click', this.pointerclick.bind(this));
        this.info = {};
    },
    pointerover: function () {
        const rig = document.getElementById('rig');
        const distance = this.el.object3D.position.distanceTo(
            rig.object3D.position
        );
        if (distance > 2.5) {
            $('.a-canvas').addClass('art-move-cursor');
        } else {
            $('.a-canvas').addClass('art-enlarge-cursor');
        }
    },
    pointerout: function () {
        $('.a-canvas').removeClass('art-enlarge-cursor art-move-cursor');
    },
    pointerclick: function (evt) {
        const rig = document.getElementById('rig');
        const cam = document.querySelector('[camera]');
        const distance = this.el.object3D.position.distanceTo(
            rig.object3D.position
        );
        if (distance > 2.5) {
            evt.stopPropagation();
            evt.preventDefault();

            $('.a-canvas').removeClass('art-move-cursor art-enlarge-cursor');

            // use art bounds to look at the middle of the painting
            const artBounds = get3DElementSize(this.el);
            //const distanceFromArt = 2;
            const pos = this.el.object3D.localToWorld(
                new THREE.Vector3(
                    Math.max(artBounds.x, artBounds.z) / 2,
                    0,
                    this.data.povDistance
                )
            );
            pos.add(this.data.povPosAdjust);

            // reset animation data in case the same art was clicked again
            if (rig.components.animation__pos)
                rig.components.animation__pos.oldData = {};

            // animate position
            rig.setAttribute(
                'animation__pos',
                `property: position; dur: 1000; to: ${pos.x} ${rig.object3D.position.y} ${pos.z}`
            );

            // get direction vector for art and convert to an angle
            const artDirection = new THREE.Vector3();
            this.el.object3D.getWorldDirection(artDirection);
            let yaw =
                Math.atan2(artDirection.x, artDirection.z) -
                rig.object3D.rotation.y;

            while (yaw - cam.object3D.rotation.y > Math.PI) yaw -= 2 * Math.PI;

            // camera rotation has to be done through look-controls
            AFRAME.ANIME({
                targets: cam.components['look-controls'].yawObject.rotation,
                y: yaw + this.data.povRotAdjust,
                duration: 1000,
                easing: 'easeInQuad',
            });
            // TODO: change pitch depending on artwork height
            AFRAME.ANIME({
                targets: cam.components['look-controls'].pitchObject.rotation,
                x: 0.1 + this.data.povPitchAdjust,
                duration: 1000,
                easing: 'easeInQuad',
            });
        } else {
            this.info = this.data;
            if (this.data.asset !== '') {
                this.info = Object.assign(
                    this.info,
                    getArtwork(this.data.asset)
                );
            }
            this.showInfo();
        }
    },
    showInfo: function () {
        let scene = document.querySelector('a-scene');

        if (scene.is('vr-mode')) {
            // 3d info in vr mode

            let infoEl = $('#vrinfo')[0];
            if (infoEl.getAttribute('visible') && infoEl.boundTo == this) {
                infoEl.setAttribute('visible', false);
                return;
            }
            infoEl.boundTo = this;

            let infoBgEl = $('#vrinfo_bg')[0],
                infoTitleEl = $('#vrinfo_title')[0],
                infoContentEl = $('#vrinfo_content')[0];
            // reset all the things
            infoTitleEl.setAttribute('text', 'value', '');
            infoContentEl.setAttribute('text', 'value', '');
            infoTitleEl.setAttribute('position', new THREE.Vector3(0, 0, 0));
            infoTitleEl.setAttribute('geometry', 'height', 'auto');
            infoContentEl.setAttribute('position', new THREE.Vector3(0, 0, 0));
            infoContentEl.setAttribute('geometry', 'height', 'auto');

            infoTitleEl.setAttribute(
                'text',
                'value',
                `${this.info.name}\n${this.info.author}, ${this.info.year}\n${this.info.size} / ${this.info.technique}`
            );
            infoContentEl.setAttribute('text', 'value', this.info.description);

            let artBounds,
                artWidth,
                artHeight,
                artDepth,
                infoTitleBounds,
                infoContentBounds,
                $img = $(this.el).children('a-image');

            artBounds = get3DElementSize(this.el);
            //console.log(artBounds);

            if (this.el.getAttribute('art')) {
                //console.log(this.el.getAttribute('art'));
                //let img = $(this.el).children('a-image')[0];
                artWidth = parseFloat(this.el.getAttribute('art').width);
            } else {
                artWidth = Math.max(artBounds.x, artBounds.z) - 0.1;
            }
            artHeight = artBounds.y - 0.1;
            artDepth = Math.min(artBounds.x, artBounds.z);

            infoTitleBounds = get3DElementSize(infoTitleEl);
            infoContentBounds = get3DElementSize(infoContentEl);
            //console.log(infoTitleBounds);
            //console.log(infoContentBounds);
            infoBgEl.setAttribute(
                'height',
                infoTitleBounds.y + infoContentBounds.y + 0.2
            );
            infoBgEl.getAttribute('position').y = infoTitleBounds.y / 2 + 0.05;
            infoTitleEl.getAttribute('position').y =
                infoContentBounds.y / 2 + infoTitleBounds.y / 2 + 0.1;

            let pos = this.el.object3D.position.clone();
            let rot = this.el.object3D.rotation.clone();
            let rotAxes = this.el.getAttribute('rotation');
            let translation = new THREE.Vector3(
                artWidth + 0.525,
                (artHeight - infoTitleBounds.y - 0.1) / 2,
                artDepth
            );
            translation.applyAxisAngle(
                new THREE.Vector3(1, 0, 0),
                THREE.Math.degToRad(rotAxes.x)
            );
            translation.applyAxisAngle(
                new THREE.Vector3(0, 1, 0),
                THREE.Math.degToRad(rotAxes.y)
            );
            translation.applyAxisAngle(
                new THREE.Vector3(0, 0, 1),
                THREE.Math.degToRad(rotAxes.z)
            );
            pos.add(translation);

            pos.add(this.data.vrInfoPosAdjust);

            infoEl.object3D.position.copy(pos);
            infoEl.object3D.rotation.copy(rot);

            infoEl.object3D.rotateY(this.data.vrInfoRotAdjust);

            infoEl.setAttribute('visible', true);
        } else {
            // basic html info
            scene.pause();
            $('#info_img').attr(
                'src',
                this.info.image ? atob(this.info.image) : this.info.src
            );

            let content = [];
            if (this.info.author) content.push(this.info.author);
            if (this.info.name) content.push(this.info.name);
            $('#info_title').text(content.join(': '));

            content = [];
            if (this.info.year) content.push(this.info.year);
            if (this.info.technique) content.push(this.info.technique);
            if (this.info.size) content.push(this.info.size);
            $('#info_subtitle').text(content.join(' | '));

            let desc = (this.info.description || '').replace(
                /(?:\r\n|\r|\n)/g,
                '<br>'
            );
            $('#info_description').html(desc);

            if (this.info.arsrc) {
                $('#info_ar').addClass('ar').attr('href', this.info.arsrc);
            } else {
                $('#info_ar').removeClass('ar');
            }

            $('#info').removeClass('hidden');
        }
    },
});
